export default [
  {
    id: 1,
    path: '/admin/home',
    meta: {
      title: '首页'
    }
  },
  {
    id: 2,
    path: '/admin/user-manage',
    meta: {
      title: '用户管理'
    },
    children: [
      {
        id: '2-1',
        path: 'user-list',
        meta: {
          title: '用户列表'
        }
      },
      {
        id: '2-2',
        path: 'role-manage',
        meta: {
          title: '角色管理'
        }
      },
      {
        id: '2-3',
        path: 'resource-manage',
        meta: {
          title: '资源管理'
        }
      }
    ]
  },
  {
    id: 3,
    path: '/admin/live-manage',
    meta: {
      title: '直播管理'
    },
    children: [
      {
        id: '3-1',
        path: 'live-room-list',
        meta: {
          title: '直播间列表'
        }
      },
      {
        id: '3-2',
        path: 'live-setting',
        meta: {
          title: '直播设置'
        }
      }
    ]
  },
  {
    id: 4,
    path: '/admin/transcoding-manage',
    meta: {
      title: '转码管理'
    },
    children: [
      {
        id: '4-1',
        path: 'video-list',
        meta: {
          title: '视频列表'
        }
      },
      {
        id: '4-2',
        path: 'video-setting',
        meta: {
          title: '视频设置'
        }
      }
    ]
  },
  {
    id: 5,
    path: '/admin/warning-manage',
    meta: {
      title: '预警管理'
    },
    children: [
      {
        id: '5-1',
        path: 'warning-list',
        meta: {
          title: '预警列表'
        }
      }
    ]
  },
  {
    id: 6,
    path: '/admin/machine-manage',
    meta: {
      title: '设备管理'
    },
    children: [
      {
        id: '6-1',
        path: 'monitor-manage',
        meta: {
          title: '监控管理'
        }
      },
      {
        id: '6-2',
        path: 'uav-task-list',
        meta: {
          title: '无人机任务列表'
        }
      },
      {
        id: '6-3',
        path: 'uav-manage',
        meta: {
          title: '无人机管理'
        }
      }
    ]
  },
  {
    id: 7,
    path: '/admin/business-manage',
    meta: {
      title: '业务管理'
    },
    children: [
      {
        id: '7-1',
        path: 'ppt-manage',
        meta: {
          title: '幻灯片管理'
        }
      },
      {
        id: '7-2',
        path: 'guide-page-manage',
        meta: {
          title: '引导页管理'
        }
      },
      {
        id: '7-3',
        path: 'tag-manage',
        meta: {
          title: '标签管理'
        }
      },
      {
        id: '7-4',
        path: 'notice-manage',
        meta: {
          title: '公告管理'
        }
      }
    ]
  },
  {
    id: 8,
    path: '/admin/system-manage',
    meta: {
      title: '系统管理'
    },
    children: [
      {
        id: '8-1',
        path: 'dictionary-manage',
        meta: {
          title: '字典管理'
        }
      },
      {
        id: '8-2',
        path: 'params-config',
        meta: {
          title: '系统参数配置'
        }
      },
      {
        id: '8-3',
        path: 'log-manage',
        meta: {
          title: '日志管理'
        }
      }
    ]
  }
];
