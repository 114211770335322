<template>
  <a-menu mode="inline" v-if="list.length" :selectedKeys="[$route.path]">
    <template v-for="item in list" :key="item.id">
      <a-menu-item v-if="!item.children" :key="item.id">
        <router-link class="title" :to="item.path">{{ item.meta.title }}</router-link>
      </a-menu-item>
      <a-sub-menu v-else :key="item.id" :title="item.meta.title">
        <template #title>
          <span>{{ item.meta.title }}</span>
        </template>
        <a-menu-item v-for="item_1 in item.children" :key="item_1.id">
          <router-link :to="`${item.path}/${item_1.path}`">{{ item_1.meta.title }}</router-link>
        </a-menu-item>
      </a-sub-menu>
    </template>
  </a-menu>
</template>

<script>
import { defineComponent } from 'vue';
import mockAdminRoutes from './mockAdminRoutes';

const list = mockAdminRoutes;

export default defineComponent({
  setup() {
    return {
      list
    };
  }
});
</script>

<style lang="less" scoped>
.ant-menu {
  padding-top: 66px;
}
</style>
